import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PostLoginService {
  constructor(private authService: AuthService) {}

  handlePostLoginOperations(): void {
    console.log('Redirecting to', this.authService.redirectUrl);
    window.location.href = this.authService.redirectUrl
      ? this.authService.redirectUrl
      : `${environment.naybrsOwnerUrl}`;
  }
}
