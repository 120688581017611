import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { AuthUser } from '../auth/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.authUser.pipe(
      take(1),
      map((user: AuthUser | null) => {
        if (user) {
          // User is authenticated, allow access
          return true;
        } else {
          // User is not authenticated, save the attempted URL and redirect to login
          this.authService.redirectUrl = state.url;
          console.log('AuthGuard - Redirect URL:', this.authService.redirectUrl);
          this.authService.saveUrlBeforeLogin(decodeURIComponent(this.authService.redirectUrl));

          return this.router.createUrlTree(['/auth/login']);
        }
      })
    );
  }
}
