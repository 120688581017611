import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthUser } from 'src/app/auth/user.model';
import { AppButtonComponent } from './app-button/app-button.component';
import { App } from 'src/app/models/misc.model';
import { NgClass } from '@angular/common';

@Component({
  selector: 'apps-menu',
  imports: [NgClass, MatIconModule, MatToolbarModule, MatButtonModule, AppButtonComponent],
  templateUrl: './apps-menu.component.html',
  styleUrl: './apps-menu.component.scss',
})
export class AppsMenuComponent {
  @Input() user: AuthUser | null;

  apps: App[] = [
    {
      name: 'Naybrs',
      code: 'naybrs',
      icon: 'home',
      url: 'https://naybrs.com',
      requiresVerification: false,
      customIcon: 'naybrs',
    },
    {
      name: 'Store',
      code: 'store',
      icon: 'store',
      url: 'https://store.naybrs.com',
      requiresVerification: false,
      customIcon: 'store',
    },
    {
      name: 'Account',
      code: 'account',
      icon: 'account_circle',
      url: 'https://store.naybrs.com/dashboard',
      requiresVerification: true,
    },
  ];
}
