import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthUser } from 'src/app/auth/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'user-menu',
  imports: [MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent {
  @Input() user: AuthUser | null;
  @Input() menuTrigger: MatMenuTrigger;

  constructor(private authService: AuthService) {}

  navigateToTerms(endPath: string) {
    window.open(`https://auth.naybrs.com/p/${endPath}`, '_blank');
  }

  async logout(): Promise<void> {
    this.authService.logout();
  }

  get initials(): string {
    if (this.user && this.user.user) {
      const firstName = this.user.user.given_name || '';
      const lastName = this.user.user.family_name || '';
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }
    return '';
  }

  closeMenu(event: Event): void {
    event.stopPropagation();
    this.menuTrigger.closeMenu();
  }
}
