import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { AuthUser } from '../auth/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthRedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.authUser.pipe(
      map((user: AuthUser | null) => {
        if (user) {
          // User is authenticated
          if (user.verified) {
            // Redirect verified users to the dashboard
            return this.router.createUrlTree(['/dashboard']);
          } else {
            // Redirect unverified users to the application page
            return this.router.createUrlTree(['/preverification']);
          }
        } else {
          // User is not authenticated, allow access to auth routes
          return true;
        }
      })
    );
  }
}
