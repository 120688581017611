import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { concatMap, switchMap } from 'rxjs/operators';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);

  // Exclude the token refresh endpoint from being intercepted
  if (req.url.includes('/store/owner/secure/refresh-tokens')) {
    return next(req);
  }

  // // Only intercept requests that contain '/secure/' in the URL
  // if (!req.url.includes('/secure/')) {
  //   return next(req);
  // }

  return authService.checkAndRefreshTokens().pipe(
    concatMap(() => {
      const user = authService.user.getValue();

      const jwt = user?.jwt;

      if (jwt) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      }
      return next(req);
    })
  );
};
