@if (!isLoading) {
<div>
  @if (devices.isMobile || devices.isTablet) {
  <div class="home-header ww-border-b flex-wrap">
    <mat-toolbar> <a [routerLink]="['/']" routerLinkActive="router-link-active">
        <naybrs-logo [width]="'120'"> </naybrs-logo>
      </a>
      <span class="example-spacer"></span>
      <!-- @if (currentRoute === '/auth/login' && !isLoggedIn) {
      <button mat-stroked-button color="primary" routerLink="/auth/signup">Open Store</button>
      }
      @if (currentRoute === '/auth/signup' && !isLoggedIn) {
      <button mat-stroked-button color="primary" routerLink="/auth/login">Log In</button>
      } -->

      <button mat-fab color="accent" class="user-icon" [matMenuTriggerFor]="appsMenu">
        <mat-icon>apps</mat-icon>
      </button>
      <mat-menu #appsMenu="matMenu" class="apps-menu">
        <apps-menu [user]="user"></apps-menu>
      </mat-menu>

      @if (isLoggedIn) {
      <button mat-fab color="accent" class="user-icon" [matMenuTriggerFor]="userMenu">
        {{ user?.user?.given_name?.charAt(0) || '' }}{{ user?.user?.family_name?.charAt(0) || '' }}
      </button>
      }

      <mat-menu #userMenu="matMenu">
        <user-menu [user]="user"></user-menu>
      </mat-menu>
    </mat-toolbar>
  </div>
  }

  @if (devices.isDesktop) {
  <div class="home-header ww-border-b">
    <div class="flex-row flex-col-sm justify-space-between items-center">
      <div class="flex-row flex-row-sm justify-center items-center">
        <mat-toolbar> <a [routerLink]="['/']" routerLinkActive="router-link-active">
            <naybrs-logo [width]="'120'"> </naybrs-logo>
          </a>
        </mat-toolbar>
      </div>
      <div class="call-bucket-wrap flex-row flex-row-sm  justify-center items-center">
        <mat-toolbar class="top-navbar top menu">

          <button mat-fab color="accent" class="user-icon" [matMenuTriggerFor]="appsMenu">
            <mat-icon>apps</mat-icon>
          </button>
          <mat-menu #appsMenu="matMenu" class="apps-menu">
            <apps-menu [user]="user"></apps-menu>
          </mat-menu>
          @if (currentRoute === '/auth/signup' && !isLoggedIn) {
          <button mat-stroked-button color="primary" routerLink="/auth/login">Log In</button>
          }
          @if (isLoggedIn) {
          <button mat-fab color="accent" class="user-icon" [matMenuTriggerFor]="userMenu">
            {{ user?.user?.given_name?.charAt(0) || '' }}{{ user?.user?.family_name?.charAt(0) || '' }}
          </button>
          <mat-menu #userMenu="matMenu" class="user-menu">
            <user-menu [user]="user"></user-menu>
          </mat-menu>
          }
          @else {
          }

        </mat-toolbar>
      </div>
    </div>
  </div>
  }



</div>
}