import { UserAccount } from '../models/user.model';

export interface AuthUser {
  jwt: string;
  jwt_refresh: string;
  jwt_expires_at: string;
  verified?: boolean;
  uuid?: string;
  user?: UserAccount;
}

export class AuthUser {
  constructor(
    public jwt: string,
    public jwt_refresh: string,
    public jwt_expires_at: string,
    public verified?: boolean,
    public user?: UserAccount
  ) {}
}
