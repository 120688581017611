import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AuthUser } from '../../auth/user.model'; // Ensure this is the correct import for the User class
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserAccount } from '../../models/user.model';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentAuthUserSubject: BehaviorSubject<AuthUser>;
  public currentAuthUser: Observable<AuthUser>;

  public get currentAuthUserValue(): AuthUser {
    return this.currentAuthUserSubject.value;
  }

  user = new BehaviorSubject<AuthUser | null>(null);
  authUser: Observable<AuthUser | null> = this.user.asObservable();

  isLoggedIn: boolean = false;

  signupObj: AuthUser = new AuthUser('', '', '', false, {} as UserAccount);

  // redirect url after login
  redirectUrl: string | null = null;

  private refreshingTokens$?: Observable<boolean>;

  setRedirectUrl(redirectUrl: string): void {
    if (!this.redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  // when user logs in and hits guard, save the url they were trying to access for redirect later
  private url = new BehaviorSubject('');
  UrlBeforeLogin = this.url.asObservable();

  saveUrlBeforeLogin(url: any) {
    this.url.next(url);
  }

  constructor(private http: HttpClient, private router: Router, private notificationsService: NotificationsService) {
    const authData: any = this.getCookie('NaybrsStore_authData') ? this.getCookie('NaybrsStore_authData') : null;

    if (authData) {
      this.currentAuthUserSubject = new BehaviorSubject<AuthUser>(authData);
      this.currentAuthUser = this.currentAuthUserSubject.asObservable();
    }
  }

  setLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }

  isAuthenticated(): true | false {
    return this.isLoggedIn;
  }

  signUp(email: string, password: string, firstName: string, lastName: string): Observable<{ email: string }> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/public/account`;
    const body = { email: email, password: password, first_name: firstName, family_name: lastName };
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<{ email: string }>(url, body, { headers });
  }

  async autoLogin() {
    const authDataString: AuthUser | null = this.getCookie('NaybrsStore_authData');

    // console.log('authDataString', authDataString);

    const userData: AuthUser | null = authDataString ? authDataString : null;

    if (!userData) {
      return;
    }

    const loadedUser = new AuthUser(userData.jwt, userData.jwt_refresh, userData.jwt_expires_at, userData.verified, {
      ...userData.user,
      pulled_from: 'cookie',
    } as UserAccount);

    console.log('loadedUser', loadedUser);

    if (loadedUser.jwt) {
      this.setUserData(loadedUser);
      // console.log('user changed constructor');
      // console.log(this.redirectUrl, 'redirect url');

      if (this.redirectUrl) {
        // console.log('made it into redirectURL');
        this.router.navigate([this.redirectUrl]);
      }
    }
  }

  getUserAccount(): Observable<UserAccount> {
    const url = `${environment.naybrsAwsApi}/store/owner/secure/store`;
    let headers;

    try {
      const storedUser: AuthUser | null = this.getCookie('NaybrsStore_authData');
      // console.log('storedUser', storedUser);
      const jwt = storedUser ? storedUser.jwt : null;

      console.log('used jwt', jwt);

      if (jwt) {
        headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` };
      } else {
        console.error('JWT is not available');
        // Redirect to login page
        this.router.navigate(['/auth/login']);
        return EMPTY;
      }
    } catch (error) {
      console.error('Failed to access localStorage', error);
      // Redirect to login page
      this.router.navigate(['/auth/login']);
      return EMPTY;
    }

    // console.log('getuser headers', headers);
    return this.http.get<UserAccount>(url, { headers }).pipe(
      map((resp: UserAccount) => {
        console.log('getUserAccount resp', resp);
        return { ...resp, pulled_from: 'db' } as UserAccount;
        // return { ...resp, pulled_from: 'db' } as UserAccount;
      }),
      catchError((error) => {
        console.error('getUserAccount error', error);
        return throwError(() => new Error('An error occurred while fetching user account details.'));
      })
    );
  }

  signUpConfirmEmail(email: string, confirmationCode: string): Observable<any> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/public/account/confirm-email`;
    const body = { email: email, confirmation_code: confirmationCode };
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<{ email: string }>(url, body, { headers });
  }

  login(un: string, pw: string): Observable<AuthUser> {
    const url = `${environment.naybrsAwsApi}/store/owner/public/jwt`;
    const body = { username: un, password: pw };
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<AuthUser>(url, body, { headers }).pipe(
      map(
        (resp: AuthUser) => {
          if (resp && resp.jwt) {
            const user: AuthUser = { ...resp, user: { ...resp.user, pulled_from: 'db' } as UserAccount };
            console.log('login user', user);
            return user;
          }
          throw new Error('An error occurred while logging in.');
        },
        catchError((error) => {
          console.error('login error', error);
          return throwError(() => new Error('An error occurred while logging in.'));
        })
      )
    );
  }

  async logout(): Promise<void> {
    this.setUserData(null);
    this.deleteCookie('NaybrsStore_authData');
    this.router.navigate(['/auth/login']);
    this.notificationsService.changeSubmitForm(null);
  }

  requestPasswordResetEmail(email: string): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/user/forgot-password/request`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = { email };
    return this.http.post(url, body, { headers }).pipe(
      map((resp: any) => {
        console.log('requestPasswordResetEmail resp', resp);

        if (resp && resp.message) {
          throw new Error(resp.data.message);
        }
        return resp.requestPasswordResetEmail;
      }),
      catchError((error) => {
        return throwError(() => new Error(error.message || 'An error occurred while requesting password reset email.'));
      })
    );
  }

  checkEmailAvailable(email: string): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/store/owner/public/email-availability/${encodeURIComponent(email)}`;
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<{ isEmailAvailable?: boolean }>(url, { headers }).pipe(
      switchMap((resp) => {
        if (resp.isEmailAvailable === undefined) {
          return throwError(() => new Error('isEmailAvailable property does not exist'));
        }
        return of(resp.isEmailAvailable);
      })
    );
  }

  resendEmailConfirmation(email: string): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/store/preverification/owner/public/account/resend-confirmation`;
    const body: { email: string } = { email };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<{ resendConfirmation?: boolean }>(url, body, { headers }).pipe(
      switchMap((resp) => {
        if (resp.resendConfirmation === undefined) {
          return throwError(() => new Error('email property does not exist'));
        }
        return of(resp.resendConfirmation);
      })
    );
  }

  confirmResetPassword(
    email: string,
    newPassword: string,
    confirmationCode: string,
    clientId: string
  ): Observable<boolean> {
    const url = `${environment.naybrsAwsApi}/user/forgot-password/confirm`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = { email, newPassword, confirmationCode, clientId };
    return this.http.post(url, body, { headers }).pipe(
      map((resp: any) => {
        console.log('confirmResetPassword resp', resp);

        if (resp && resp.message) {
          throw new Error(resp.message);
        }
        return resp.passwordChangeConfirmed;
      }),
      catchError((error: HttpErrorResponse) => {
        this.notificationsService.openSnackBar('Something went wrong. Please try again.');
        return throwError(() => new Error(error.message || 'An error occurred while confirming password reset.'));
      })
    );
  }

  setUserData(tokens: AuthUser | null) {
    if (!tokens) {
      this.user.next(null);
      return;
    }

    console.log('AuthObj from setUserData', tokens);

    const authUser = new AuthUser(tokens.jwt, tokens.jwt_refresh, tokens.jwt_expires_at, tokens.verified, tokens.user);

    this.user.next(authUser);
    this.storeAuthData(authUser);
  }

  private async storeAuthData(authUser: AuthUser) {
    // console.log('AuthObj from storedata', authUser);

    // trimming unnecessary data from authUser becasuse of max cookie size
    const trimmedAuthUser = {
      ...authUser,
      user: {
        email: authUser?.user?.email,
        given_name: authUser?.user?.given_name,
        family_name: authUser?.user?.family_name,
        under_review: authUser?.user?.under_review || false,
      },
      verified: authUser.verified || false,
    };

    this.setCookie('NaybrsStore_authData', JSON.stringify(trimmedAuthUser), authUser.jwt_expires_at);
  }

  postUserVerifyAccountCreationAndAutologin(clientId: string, username: string): Observable<any> {
    const url: string = `${environment.naybrsAwsApi}/store/owner/public/verify/post-verify/autologin`;
    const body: {
      clientId: string;
      username: string;
    } = {
      clientId,
      username,
    };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, body, { headers });
  }

  private refreshAuthTokens(): Observable<any> {
    const storedUser: AuthUser | null = this.user.getValue();

    const jwt_refresh: string | null = storedUser ? storedUser.jwt_refresh : null;

    const jwt: string | null = storedUser ? storedUser.jwt : null;

    if (!jwt_refresh || !jwt) {
      return throwError('JWT or JWT refresh token is missing');
    }

    const url: string = `${environment.naybrsAwsApi}/store/owner/secure/refresh-tokens`;
    const body = { jwt_refresh, jwt };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, body, { headers });
  }

  refreshSetAuthTokens(): Observable<boolean> {
    return this.refreshAuthTokens().pipe(
      map((resp: any) => {
        console.log('refreshAuthTokens resp', resp);

        if (!resp.jwt || !resp.jwt_expires_at) {
          console.error('Invalid response from refreshAuthTokens');
          this.logout();
          return false;
        }

        const currentUser = this.user.getValue();

        console.log('currentUser', currentUser);

        if (!currentUser) {
          console.error('Current user is null');
          this.logout();
          return false;
        }

        const authUser: AuthUser = {
          ...currentUser,
          jwt: resp.jwt as string,
          jwt_expires_at: resp.jwt_expires_at as string,
          jwt_refresh: currentUser.jwt_refresh as string,
        };

        console.log('authUser after refresh', authUser);

        this.setUserData(authUser);
        return true;
      }),
      // Refresh user account after successful token refresh
      tap((success: boolean) => {
        if (success) {
          this.getUserAccount().subscribe({
            next: (resp) => {
              this.setUserData(
                Object.keys(resp).length === 0 ? null : ({ ...this.user.getValue(), user: resp } as AuthUser)
              );
            },
            error: (error) => {
              console.error('Failed to fetch user account:', error);
            },
          });
        }
      }),
      catchError((error) => {
        console.error('refreshAuthTokens error', error);
        this.logout();
        return of(false);
      })
    );
  }

  checkAndRefreshTokens(): Observable<boolean> {
    console.log('checkAndRefreshTokens');

    // If a refresh is already in progress, return the same Observable
    if (this.refreshingTokens$) {
      return this.refreshingTokens$;
    }

    // Attempt to refresh tokens regardless of cookie expiry
    const currentUser = this.user.getValue();
    if (currentUser && this.isTokenExpired(currentUser.jwt_expires_at)) {
      console.log('Token is expired, attempting to refresh tokens.');
      this.refreshingTokens$ = this.refreshSetAuthTokens().pipe(
        finalize(() => {
          // Clear the stored Observable after completion
          this.refreshingTokens$ = undefined;
        }),
        shareReplay(1) // share result with all subscribers
      );
      return this.refreshingTokens$;
    }

    console.log('Token is not expired. Returning true observable.');
    return of(true);
  }

  private isTokenExpired(expiry: string): boolean {
    const expiryDate = new Date(expiry);
    return expiryDate <= new Date();
  }

  setCookie(name: string, value: string, expiryTime: string) {
    // console.log('Cookie value', JSON.parse(value));

    if (!name || !value) {
      console.log('Cookie name or value is missing');
      return;
    }

    let expires = '';
    if (expiryTime) {
      const date = new Date(expiryTime);
      if (!isNaN(date.getTime())) {
        expires = '; expires=' + date.toUTCString();
        // Store expiry in localStorage
        localStorage.setItem(`${name}_expiry`, expiryTime);
      } else {
        console.warn('Invalid expiryTime provided. The cookie will be a session cookie.');
      }
    } else {
      console.warn('No expiryTime provided. The cookie will be a session cookie.');
    }

    const isSecure = location.protocol === 'https:';

    // Determine the appropriate domain for cross-subdomain cookies
    let domain = '';
    const hostname = window.location.hostname;

    if (hostname !== 'localhost') {
      if (hostname.includes('demo.store.naybrs.com')) {
        // Set cookie to work for both auth.demo.naybrs.com and demo.naybrs.com
        domain = '.demo.naybrs.com';
      } else if (hostname.includes('staging.store.naybrs.com')) {
        // Set cookie to work for both auth.staging.naybrs.com and staging.naybrs.com
        domain = '.staging.naybrs.com';
      } else if (hostname.includes('store.naybrs.com')) {
        // Set cookie to work for both auth.naybrs.com and naybrs.com
        domain = '.naybrs.com';
      }
    }

    let cookieString = `${name}=${value || ''}${expires}; path=/; SameSite=Lax`;
    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    if (isSecure) {
      cookieString += '; Secure';
    }

    document.cookie = cookieString;
  }

  private isCookieExpired(name: string): boolean {
    const expiry = localStorage.getItem(`${name}_expiry`);
    if (!expiry) return true;

    const expiryDate = new Date(expiry);
    return expiryDate <= new Date();
  }

  getCookie(key: string): any {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        const cookieValue = c.substring(name.length, c.length);
        // console.log('Cookie Value:', cookieValue);
        try {
          return JSON.parse(cookieValue);
        } catch (error) {
          console.warn('Invalid JSON, returning raw value:', cookieValue);
          return cookieValue;
        }
      }
    }
    return null;
  }

  deleteCookie(name: string) {
    if (!name) {
      console.log('Cookie name is missing');
      return;
    }

    // Determine the appropriate domain for cross-subdomain cookies
    let domain = '';

    const hostname = window.location.hostname;

    if (hostname !== 'localhost') {
      if (hostname.includes('demo.store.naybrs.com')) {
        // Set cookie to work for both auth.demo.naybrs.com and demo.naybrs.com
        domain = '.demo.naybrs.com';
      } else if (hostname.includes('staging.store.naybrs.com')) {
        // Set cookie to work for both auth.staging.naybrs.com and staging.naybrs.com
        domain = '.staging.naybrs.com';
      } else if (hostname.includes('store.naybrs.com')) {
        // Set cookie to work for both auth.naybrs.com and naybrs.com
        domain = '.naybrs.com';
      }
    }

    let cookieString = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax`;
    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    cookieString += '; Secure';
    localStorage.removeItem(`${name}_expiry`);

    document.cookie = cookieString;
    // console.log('Cookie Deleted:', cookieString);
  }
}
