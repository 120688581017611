<div class="flex flex-col justify-between h-100">
    <mat-toolbar class="menu-toolbar">
        <button mat-icon-button style="visibility: hidden"><mat-icon>close</mat-icon></button>
        <span class="toolbar-title font-medium">{{user?.user?.email}}</span>
        <button mat-icon-button><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    <div class="flex flex-col items-center justify-center space-y-4">
        <div class="flex flex-col items-center justify-center space-y-4">
            <div
                class="flex items-center justify-center w-24 h-24 rounded-full bg-gray-300 text-2xl font-bold text-white">
                {{ initials }}
            </div>
            <h4>Hi, {{user?.user?.given_name}}!</h4>

            @if (user?.verified) {
            <button mat-stroked-button routerLink="/dashboard">
                <span>Manage your Naybrs Store Account</span>
            </button>
            }

        </div>
        <div>
            <!-- <button mat-menu-item>
            <mat-icon>logout</mat-icon>
            <span>Do something cool</span>
          </button> -->
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
            </button>
        </div>

    </div>
    <mat-toolbar class="menu-bottom-toolbar">
        <button mat-button (click)="navigateToTerms('privacy-policy')">Privacy Policy</button>
        •
        <button mat-button (click)="navigateToTerms('terms-and-conditions')">Terms of Service</button>
    </mat-toolbar>
</div>