import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { FullPageLoadingComponent } from '../../../shared/full-page-loading/full-page-loading.component';
import { AuthFederatedService } from '../../../shared/services/auth-federated.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { NotificationsService } from '../../../shared/services/notifications.service';

@Component({
  selector: 'app-oauth-callback',
  imports: [FullPageLoadingComponent],
  templateUrl: './oauth-callback.component.html',
  styleUrl: './oauth-callback.component.scss',
})
export class OauthCallbackComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authFederatedService: AuthFederatedService,
    private authService: AuthService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    // grab the path and query parameters from the URL
    const urlSegments: UrlSegment[] = this.route.snapshot.url;
    const currentRoute = '/' + urlSegments.map((segment) => segment.path).join('/');
    const params: Params = this.route.snapshot.queryParams;

    console.log('OAUTH CALLBACK URL', currentRoute, params);

    // FOR OAUTH SIGNUP
    if (currentRoute === '/callback') {
      //O AUTH TO BE IMPLEMENTED LATER
      // const code = params['code'];
      // if (code) {
      //   console.log('OAUTH CALLBACK QUERY PARAMS', params);
      //   // Remove query parameters from the URL
      //   const url = new URL(window.location.href);
      //   url.search = '';
      //   window.history.replaceState({}, document.title, url.toString());
      //   this.authFederatedService
      //     .federatedSignIn(this.stripUrlFragment(url.toString()), code, environment.cognitoDomain)
      //     .subscribe((response: CognitoToken) => {
      //       console.log('OAUTH CALLBACK', response);
      //       // Send the code to the main window
      //       window.opener.postMessage({ tokens: response }, window.location.origin);
      //       // Optionally, close the authentication window
      //       window.close();
      //     });
      // } else {
      //   console.log('No code in query params', params);
      // }
    }
    // FOR EMAIL SIGNUP
    else if (currentRoute === '/verify') {
      console.log('VERIFY ACCOUNT /verify', params);

      const clientId = params['client_id'];
      const username = params['user_name'];

      this.notificationsService.openSnackBar('Creating account. Do not close page or refresh', 0);

      this.authService.postUserVerifyAccountCreationAndAutologin(clientId, username).subscribe({
        next: (response: any) => {
          console.log('VERIFY RESPONSE', response);

          this.notificationsService.dismissSnackBar();

          if (response.message) {
            this.notificationsService.openSnackBar(response.message);
          }

          if (response.IdToken) {
            this.authService.setUserData(response);

            const url = new URL(environment.naybrsOwnerUrl);
            url.searchParams.set('referrer', 'store-auth');
            url.searchParams.set('action', 'account-created');
            const updatedUrl = url.toString();

            window.location.href = updatedUrl;
            this.notificationsService.openSnackBar('Account created and logged in'!);
          }
        },
        error: () => {
          this.notificationsService.openSnackBar('Error verifying account. Please try again.', 5000);
          this.router.navigate(['/']);
        },
      });
    }
  }

  // because facebook adds a fragment to the url, we need to strip it out
  stripUrlFragment(url: string): string {
    const urlWithoutFragment = url.split('#')[0];
    return urlWithoutFragment;
  }
}
