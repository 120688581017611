import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-full-page-loading',
    imports: [MatProgressSpinnerModule],
    templateUrl: './full-page-loading.component.html',
    styleUrl: './full-page-loading.component.scss'
})
export class FullPageLoadingComponent {}
