import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { AuthService } from './shared/services/auth.service';
import { AuthUser } from './auth/user.model';
import { UserAccount } from './models/user.model';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  isLoggedIn: boolean = false;
  displayHeader: boolean = false;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.handleUserSession();

    // Subscribe to route changes
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateHeaderDisplay();
    });
  }

  handleUserSession() {
    this.authService.autoLogin();

    const authData = this.authService.getCookie('NaybrsStore_authData');

    if (authData) {
      this.isLoggedIn = true;
    }

    if (this.isLoggedIn) {
      this.authService.getUserAccount().subscribe((resp: UserAccount) => {
        this.authService.setUserData(Object.keys(resp).length === 0 ? null : ({ ...authData, user: resp } as AuthUser));
      });
    }
  }

  updateHeaderDisplay() {
    const currentRoute = this.router.url;
    switch (true) {
      case currentRoute.includes('/dashboard'):
      case currentRoute.includes('/preverification'):
        this.displayHeader = true;
        break;
      case currentRoute.includes('/auth'):
        this.displayHeader = false;
        break;
      default:
        this.displayHeader = true;
        break;
    }
  }

  test() {
    console.log('test');
    this.authService.checkAndRefreshTokens().subscribe((resp) => {
      console.log('checkAndRefreshTokens resp', resp);
    });
  }
}
