import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { AuthUser } from '../auth/user.model';

@Injectable({
  providedIn: 'root',
})
export class UnverifiedAccountGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.authUser.pipe(
      map((user: AuthUser | null) => {
        if (!user) {
          // User not authenticated, redirect to login
          return this.router.createUrlTree(['/auth/login']);
        } else if (user.verified) {
          // User is verified, redirect to dashboard
          return this.router.createUrlTree(['/dashboard']);
        } else {
          // User is unverified, allow access
          return true;
        }
      })
    );
  }
}
