import { Component } from '@angular/core';

import { NaybrsLogoComponent } from '../naybrs-logo/naybrs-logo.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';

import { MatButton, MatButtonModule } from '@angular/material/button';
import { filter } from 'rxjs';
import { AuthUser } from '../../auth/user.model';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { AppsMenuComponent } from './components/apps-menu/apps-menu.component';
import { BreakpointService } from '../services/breakpoint.service';
import { DeviceBreakpoints } from 'src/app/models/misc.model';

@Component({
  selector: 'app-header',
  imports: [
    NaybrsLogoComponent,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatButton,
    MatButtonModule,
    UserMenuComponent,
    AppsMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isLoggedIn: boolean = false;
  user: AuthUser | null;
  isLoading: boolean = false;
  currentRoute: string;

  devices: DeviceBreakpoints;

  constructor(
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private breakpointService: BreakpointService
  ) {
    this.breakpointService.devices$.subscribe((devices) => (this.devices = devices));
  }

  async ngOnInit() {
    // Subscribe to the router's events observable
    this.router.events
      .pipe(
        // Filter out events that are not NavigationEnd events
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        // Update currentRoute with the url of the NavigationEnd event
        this.currentRoute = event.url;
      });

    this.authService.authUser.subscribe(async (resp: any) => {
      console.log('current user', resp);

      if (resp && Object.keys(resp).length > 0) {
        this.isLoggedIn = true;
        this.user = resp;
      } else return;
    });
  }

  goToApp(): void {
    console.log('go to app');
  }

  navigateToLogin() {
    this.router.navigate(['/account'], {
      queryParams: {
        mode: 'login',
      },
    });
  }

  changeTab(url: string) {
    this.router.navigate(['p/profile/'], {
      //relativeTo: this.route,
      queryParams: {
        section: url,
      },
    });
  }
}
