import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { App } from 'src/app/models/misc.model';

@Component({
  selector: 'app-button',
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './app-button.component.html',
  styleUrl: './app-button.component.scss',
})
export class AppButtonComponent {
  @Input() app: App;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('naybrs', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/n.svg'));
    this.matIconRegistry.addSvgIcon(
      'store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/store.svg')
    );
  }

  ngOnInit() {}

  navigateToApp() {
    if (this.app) {
      window.open(this.app.url, '_blank');
    }
  }
}
