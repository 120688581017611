import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { VerifiedAccountGuard } from './guards/verified-account.guard';
import { UnverifiedAccountGuard } from './guards/unverified-account.guard';
import { OauthCallbackComponent } from './auth/components/oauth-callback/oauth-callback.component';
import { AuthRedirectGuard } from './guards/auth-redirect-guard';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // automatic root redirect to login
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then((m) => m.authRoutes),
    canActivate: [AuthRedirectGuard],
  },
  {
    path: 'preverification',
    loadChildren: () =>
      import('./sections/preverification/preverification.routes').then((m) => m.preverificationRoutes),
    canActivate: [AuthGuard, UnverifiedAccountGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./sections/store-owners/store-owners.routes').then((m) => m.storeOwnersRoutes),
    canActivate: [AuthGuard, VerifiedAccountGuard],
  },

  // // Yet to be build web dashboard. Currently, we are using the mobile app.
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, VerifiedAccountGuard] },
  { path: 'verify', component: OauthCallbackComponent },
];
