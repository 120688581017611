import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'naybrs-logo',
    imports: [CommonModule],
    templateUrl: './naybrs-logo.component.html',
    styleUrls: ['./naybrs-logo.component.scss']
})
export class NaybrsLogoComponent {
  @Input('width') width: string;
  @Input('colorMode') colorMode: string = 'dark';
}
